<template>
  <div>
    <a
      href="https://www.a-q-f.com/openpc/sugoroku/index.html?incd=header_gnav_sugoroku"
      class="sp btn btn_back_to_top"
      v-if="is_balloon"
    >
      TOPページに戻る
    </a>
  </div>
  <div id="footer">
    <div id="company_link" v-if="is_show_company_link">
      <a href="https://www.ecoin.co.jp/">運営会社</a>
    </div>
    <div>Copyright ©2022 ecoin Inc. All Rights Reserved. v2.17.7(215)</div>
  </div>
</template>

<script>
export default {
  name: "Footer",
  components: {},
  props: {
    is_balloon: {
      type: Boolean,
      default: false,
    },
    is_show_company_link: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  computed: {},
  methods: {},
  watch: {},
  mounted: function () {
    // nothing
  },
};
</script>

<style lang="scss" scoped></style>
